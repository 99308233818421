.s-projects {
  background-color: #f0f0f0;
  min-height: 70vh;
  padding-bottom: 40px;
  font-family: $font-family-base;

  &--main {
    width: 100%;

    h2 {
      font-size: 24px;
      color: $bg-menu;
      margin-bottom: 20px;
    }
  }

  .o-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: #f1f3f6;
  }

  &--card {
    background-color: #fff;
    margin-bottom: 20px;
    min-height: 300px;
    padding: 20px;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 10px 15px -3px #00000033, 0 4px 6px -2px #00000040;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &--cover {
    flex: 1 50%;
    width: 100%;
  }

  &--info {
    flex: 1 50%;
    padding: 0 0 0 20px;

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  &--title {
    font-size: 24px !important;
    font-family: $font-family-base;
    margin: 0;
    font-weight: bold;
    color: $bg-menu;
  }

  &--support {
    font-size: 16px;
    font-family: $font-family-base;
    color: $title-color;
    width: 100%;
  }

  &-subtitle {
    text-align: center;
    font-size: 22px;
    font-family: $font-family-base;
    margin: 20px 0;
    color: $bg-menu;
  }

  &--material {
    &-title {
      font-size: 14px;
      font-weight: bold;
      font-family: $font-family-base;
      margin: 40px 0 20px;
    }
  }

  &--buttons {
    display: flex;
    align-items: center;

    .o-btn + .o-btn {
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .o-btn + .o-btn {
        margin-left: 0;
      }
    }
  }
}
