.s-pages {
  min-height: 70vh;
  padding-bottom: 40px;

  .container {
    margin-top: 40px;
    background-color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 700px;
    font-size: 14px;

    p,
    li,
    span {
      font-family: $font-family-base !important;
      text-decoration-line: none !important;
    }

    table {
      width: 100% !important;
    }

    a {
      color: $bg-menu !important;
      text-decoration: none !important;
      padding-bottom: 2px;
      border-bottom: 1px solid $bg-menu;

      &:hover,
      &:visited {
        color: darken($bg-menu, 10%);
        text-decoration: none;
      }
    }

    ul {
      margin-left: 30px;
      margin-top: 30px;
    }
  }
}
