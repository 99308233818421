.c-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  &--color {
    &-gray {
      background-color: #f5f5f5;
    }
  }

  &--maxwidth {
    &-300 {
      max-width: 300px;
    }
    &-400 {
      max-width: 400px;
    }
    &-500 {
      max-width: 500px;
    }
  }

  &--label,
  span {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    position: relative;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
