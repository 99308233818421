.c-materials-list {
  &__item {
    background-color: $light-gray-2;
    border-radius: 4px;
    margin: 0 0 15px 0;
    padding: 35px 25px 35px 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 0;

    &--center {
      justify-content: center;
    }
  }

  &__type {
    width: 15%;
    max-width: 30px;
    max-height: 35px;
    fill: $brand-primary;
    flex-shrink: 0;
    align-self: flex-start;

    @include desktop() {
      width: 30px;
      height: 35px;
    }
  }

  &__data {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-left: 25px;

    @include desktop() {
      width: auto;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: inherit;

    @include desktop() {
      width: auto;
    }

    &--full {
      width: 100%;
    }
  }
}
