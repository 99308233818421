.c-box-info {
  background-color: $light-gray-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 4px;

  &--lp {
    padding: 30px;
  }

  &--ctr {
    justify-content: center;
  }

  &--bg {
    &-primary {
      background-color: $brand-primary;
    }
  }
}
