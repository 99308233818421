.s-faq {
  padding-bottom: 200px;

  @media (max-width: 768px) {
    padding-bottom: 300px;
  }

  .o-input--faq {
    width: 100%;
    border: none;
    height: 40px;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
