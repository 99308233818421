.s-materials {
  background-color: #f0f0f0;
  min-height: 70vh;
  padding-bottom: 40px;
  font-family: $font-family-base;

  &--sidebar {
    &-category {
      margin: 20px 0 5px;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    &-file {
      color: #fff;
      font-size: 14px;
      margin: 0 0 0 10px;
      cursor: pointer;

      & + .s-materials--sidebar-category {
        margin-top: 10px;
      }
    }
  }

  &--main {
    h2 {
      font-size: 24px;
      color: $bg-menu;
      margin-bottom: 5px;
    }
  }

  &--title {
    font-size: 18px;
    font-family: $font-family-base;
    margin: 0;
    font-weight: bold;
    color: $bg-menu;
  }

  &--support {
    font-size: 16px;
    font-family: $font-family-base;
    margin-bottom: 20px;
    color: $title-color;
  }

  &-subtitle {
    text-align: center;
    font-size: 22px;
    font-family: $font-family-base;
    margin: 20px 0;
    color: $bg-menu;
  }
}
