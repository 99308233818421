.c-card {
  overflow: hidden;
  padding-bottom: 60px;
  padding-top: 30px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  font-family: $font-family-base !important;
  box-shadow: 0px 2px 4px #15152233;

  &.slick-slide {
    margin: 0 10px;
    height: inherit;
  }

  &__img-wrapper {
    display: block;
    height: 200px;
    overflow: hidden;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    object-position: center;
  }

  &__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  &__calendar {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__ttl {
    font-size: 16px;
    line-height: 22px;
    color: $brand-primary;
    font-weight: 600;
    overflow: hidden;
    margin-bottom: 15px;
  }

  &__status {
    border: 1px solid #e7e7e7;
    border-radius: 15px;
    padding: 3px 10px;
    font-size: 12px;
    color: #b2b2b2;
    width: fit-content;
    margin-bottom: 15px;
    display: flex;

    &:before {
      content: '';
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.414' height='7.874' viewBox='0 0 11.414 7.874'%3E%3Cpath id='Caminho_2106' data-name='Caminho 2106' d='M5,10.333l3.333,3.333L15,7' transform='translate(-4.293 -6.293)' fill='none' stroke='%231dc7b6' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      background-color: $brand-secondary;
      opacity: 0;
      visibility: hidden;
      width: 16px;
      height: 16px;
      margin-right: 7px;
      margin-top: 1px;
      display: none;
    }

    &.active {
      border-color: $brand-secondary;
      color: $brand-secondary;

      &:before {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }

  &__actions {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 20px;
    z-index: 1;

    .o-btn {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__chip {
    color: $brand-primary-400;
    background-color: $brand-tertiary;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    padding: 6px 8px;
    font-weight: bold;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
    }

    &--date {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.52 15.52'%3E%3Cpath data-name='Caminho 2069' d='M0 0h15.52v15.52H0z' fill='none'/%3E%3Cpath data-name='Caminho 2070' d='M12.93 1.94h-.65V.64H11v1.3H4.52V.64H3.23v1.3H2.6a1.3 1.3 0 00-1.3 1.3v10.33a1.3 1.3 0 001.3 1.3h10.34a1.3 1.3 0 001.3-1.3V3.23a1.3 1.3 0 00-1.3-1.3zm0 11.63H2.59v-8.4h10.34z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }
    &--time {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.52 15.52'%3E%3Cpath data-name='Caminho 2071' d='M0 0h15.52v15.52H0z' fill='none'/%3E%3Cpath data-name='Caminho 2072' d='M7.75 1.3a6.46 6.46 0 106.47 6.46 6.46 6.46 0 00-6.47-6.47zm0 11.63a5.17 5.17 0 115.18-5.17 5.17 5.17 0 01-5.17 5.17z' fill='%23151522'/%3E%3Cpath data-name='Caminho 2073' d='M8.08 4.53h-.97V8.4l3.4 2.04.48-.8-2.9-1.72z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }
    &--location {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.52 15.52'%3E%3Cpath data-name='Caminho 2074' d='M0 0h15.52v15.52H0z' fill='none'/%3E%3Cpath data-name='Caminho 2075' d='M7.76 1.3a4.52 4.52 0 00-4.53 4.52c0 3.4 4.53 8.4 4.53 8.4s4.52-5.01 4.52-8.4a4.52 4.52 0 00-4.52-4.53zm0 6.13a1.62 1.62 0 111.61-1.61 1.62 1.62 0 01-1.61 1.61z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }
  }
}
