.c-gallery-modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-70%);
  z-index: 50;
  max-width: 80vw;
  max-height: 80vh;
  transition: all 0.2s ease-out, transform 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.5s 0.4s ease-out, transform 0.3s 0.4s ease-out;
  }

  &__img,
  &__video {
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  &__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: 0;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      text-decoration: none;
    }

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg data-name='Grupo 641' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cpath data-name='Caminho 2095' d='M0 0h22v22H0z' fill='none'/%3E%3Cpath data-name='Caminho 2096' d='M3 3l16 16m0-16L3 19' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
      background-position: center;
      background-size: contain;
      width: 18px;
      height: 18px;
      margin-left: 8px;
    }
  }

  &__actions {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__arrow {
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 20'%3E%3Cpath d='M9.311.276a1 1 0 011.379 1.448L3.019 9H23a1 1 0 110 2H3.051l7.639 7.276a1 1 0 11-1.379 1.448l-8.725-8.31A2 2 0 01.6 8.569z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;

    &--prev {
      left: -60px;
    }

    &--next {
      right: -60px;
      transform: rotateZ(180deg);
    }
  }
}
