.s-profile {
  min-height: 80vh;
  .container {
    margin-top: 80px;
    padding-bottom: 40px;
  }

  &--cover {
    text-align: center;
    margin: 10px auto;

    span {
      font-size: 80px;
      color: $bg-menu;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &--thumb {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
}
