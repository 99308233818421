@keyframes draw {
  0% {
    stroke-dasharray: 0 790;
    opacity: 1;
  }
  100% {
    stroke-dasharray: 790 0;
    opacity: 1;
  }
}
