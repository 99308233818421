.o-nav {
  background-color: $bg-menu;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  top: 0;

  button {
    margin-bottom: 0;
  }
}

.o-menu {
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  right: -360px;
  top: 55px;
  width: 360px;
  min-height: 380px;
  background-color: $bg-menu;
  display: flex;
  flex-flow: row wrap;
  transition: all 0.2s linear;

  &--opened {
    right: 0;
    top: 65px;
  }

  .o-row {
    width: 100%;
    justify-content: space-between;

    a {
      width: 100px;
      margin: 0 5px;
    }
  }
}
