.c-list-blog {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0;
  gap: 20px 0;

  @include desktop() {
    grid-template-columns: repeat(1, 33fr 33fr 33fr);
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }

  &__img-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;
  }

  &__link {
    transition: 0s;
    &:hover {
      color: #e6e6e6;
    }
  }

  &__date {
    margin-bottom: 10px;
    font-size: 12px;
    display: block;
    color: #4d4d4f;
  }

  &__ttl {
    font-family: $font-family-base;
    font-size: 14px;
    color: $neutral-black;
  }

  &__text {
    padding-left: 20px;
    padding-top: 2px;
  }
}
