.c-footer {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background-color: $brand-secondary;
  padding: 10px 0;

  &--title {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-family-base;
    margin: 30px 0;
  }

  .col {
    padding-bottom: 10px;
  }

  a,
  p {
    display: block;
    color: #fff;
    margin-bottom: 10px;
  }

  &--socials {
    display: flex;
    margin-top: 50px;
    border-top: 1px solid #fff;
    padding-top: 25px;

    .fa {
      font-size: 30px;
      margin-right: 20px;
    }
  }
}
