.c-breadcrumbs {
  background-color: $brand-primary;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 2px 0;

  a {
    opacity: 0.5;
    position: relative;
    padding-right: 20px;
    color: #fff;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 0;
    opacity: 0.8;
    color: #fff;
  }

  span {
    width: 17px;
    font-size: 10px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
