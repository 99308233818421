.o-label {
  display: block;
  color: $brand-primary;
  font-size: 12px;
  font-family: $font-family-base;

  &--button {
    border: 1px solid $bg-menu;
    color: $bg-menu;
    padding: 5px 15px;
  }

  &--rounded {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  @media (max-width: $screen-md) {
    display: block;
    width: 100%;
  }

  &--thin {
    font-weight: 300;
  }

  &--radio {
    position: relative;
    overflow: hidden;
    padding-left: 30px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    &::before {
      width: 18px;
      height: 18px;
      border: 1px solid $gray;
      background-color: $white;
    }

    &::after {
      background-color: $tomato;
      width: 10px;
      height: 10px;
      margin: 4px 0 0 4px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    input {
      position: absolute;
      left: -30px;
    }

    &.is-active::after {
      opacity: 1;
    }
  }

  .o-form-inline & {
    & + .o-label {
      margin-top: 10px;
      @media (min-width: $screen-md) {
        margin-top: 0;
        margin-left: 38px;
      }
    }
  }
}
