.c-profile {
  display: flex;
  height: auto;
  margin-top: auto;
  margin-bottom: 5px;

  @include below(1024px) {
    flex-direction: column;
    align-items: center;
  }

  &__img-wrapper {
    width: 32%;

    @include below(1024px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__content-info {
    width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;

    @include below(1024px) {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }

  &__img {
    width: 100%;
    max-width: 112px;
    max-height: 112px;

    @include below(1024px) {
      margin: 0 auto;
      display: block;
    }
  }

  &__ttl {
    font-size: 20px;
    line-height: 1;
    margin: 0 0 15px;
  }

  &__info {
    font-size: 16px;
  }
}
