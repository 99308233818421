.c-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  [data-controller='committees'] & {
    padding: 0;
    margin-bottom: 0;
    display: block;
  }

  &--color {
    &-brand {
      background-color: $bg-menu;
    }
  }

  &--panel {
    background-color: #fff;
    position: absolute;
    left: 20px;
    top: 70px;
    z-index: 3;
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    padding: 30px;
    box-shadow: 0px 1px 1px #002600;
    max-width: 60%;
    height: 500px;
    overflow-y: scroll;

    [data-controller='committees'] & {
      left: 0;
      top: 0;
      width: 100%;
      max-width: 400px;
    }

    &-message {
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      p {
        text-align: center;
        max-width: 80%;
        font-size: 14px;
        color: #151522;
        margin: 0 auto;
      }

      a {
        color: #151522;
        text-decoration: underline;
      }
    }
  }

  input {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &--panel {
      left: 0;
      max-width: 100%;
    }
  }
}

#guests-table .o-checkbox,
#guests-table .guests-hidden {
  display: none;
}
