.s-enter {
  padding-top: 60px;

  @media (min-width: 768px) {
    max-width: 500px;
    margin: 30px auto;
  }
}

.s-register {
  background-color: #fff;
  min-height: calc(100vh - 60px);

  &--header {
    background-color: $brand-dark;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 20px;
    margin-top: 62px;

    h2 {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  @media (min-width: 768px) {
    form {
      max-width: 500px;
      margin: 50px auto;
    }
  }
}

.s-success {
  background-color: $bg-menu;
  min-height: calc(100vh - 60px);

  &--header {
    background-color: $brand-dark;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 20px;
    margin-top: 62px;

    h2 {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .card {
    max-width: 50vw;
    min-height: 70vh;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: none;
    }
  }
}

.s-auth {
  width: 100vw;
  min-height: calc(100vh - 80px);
  background-color: #fff;
  display: flex;
  align-items: center;

  .o-forms {
    margin: 0 auto;
    @media (min-width: 768px) {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  &--link {
    width: 100%;
    text-align: center;
    display: block;

    &-logo {
      width: 120px;
    }
  }
}

.s-share {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--text {
    font-weight: 700;
  }

  .row {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .social-media {
      margin-left: 10px;

      &--whatsapp {
        min-width: 60px;
        border-color: #4fce5d;
        background: #4fce5d;
        padding: 10px 15px;
      }

      .fa-whatsapp {
        color: #fff;
        font-size: 20px;
      }

      &--facebook {
        min-width: 60px;
        border-color: #091e42;
        background: #091e42;
        padding: 10px 15px;
      }

      .fa-facebook {
        color: #fff;
        font-size: 20px;
      }
    }
  }
}
