.o-ttl {
  font-family: $font-family-base;
  font-weight: bold;
  color: $brand-primary;

  &--title {
    color: $title-color;
    font-size: 20px;
    font-weight: bold;

    &-big {
      font-size: 30px;
    }
  }

  &--subtitle {
    color: $title-color;
    font-size: 16px;
    font-weight: normal;
    text-transform: inherit;
  }

  &--text {
    color: $text-color;
    font-size: 14px;
  }

  &--lineheight {
    &-1 {
      line-height: 1;

      &-2 {
        line-height: 1.2;
      }

      &-3 {
        line-height: 1.3;
      }
    }

    &-2 {
      line-height: 1.5;
    }
  }

  &--color {
    &-brand {
      color: $bg-menu;
    }

    &-blue {
      color: $brand-primary;
    }

    &-gray {
      color: $text-color;
    }

    &-white {
      color: #fff;
    }

    &-yellow {
      color: $yellow;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--weight {
    &-bold {
      font-weight: bold;
    }
  }

  &--link {
    color: $bg-menu;
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 1px solid $bg-menu;

    &:hover,
    &:visited {
      color: darken($bg-menu, 10%);
      text-decoration: none;
    }
  }

  &--size {
    &-10 {
      font-size: 10px;
    }
    &-11 {
      font-size: 11px;
    }
    &-12 {
      font-size: 12px;
    }
    &-13 {
      font-size: 13px;
    }
    &-14 {
      font-size: 14px;
    }
    &-15 {
      font-size: 15px;
    }
    &-16 {
      font-size: 16px;
    }
    &-18 {
      font-size: 18px;
    }
    &-20 {
      font-size: 20px;
    }
    &-24 {
      font-size: 24px;
    }
    &-25 {
      font-size: 25px;
    }
  }
}

.o-sbttl {
  color: $title-color;
  font-size: 16px;
  font-weight: normal;
  text-transform: inherit;
}
