.c-filter-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 20px;

    @include desktop() {
      @for $i from 1 to 100 {
        &--#{$i} {
          width: calc(#{$i + '%'} - 20px);
        }
      }
    }
  }

  &__lbl {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: $brand-primary;
    white-space: nowrap;
    padding: 0 10px 0 0;
    margin: 0;
  }
}
