.c-content-info {
  width: 100%;

  &__btn-top-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .o-btn {
      @include below(1024px) {
        width: 100%;
      }
    }
  }
}
