.c-list-cards {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(1, 1fr);

  @include above(500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include desktop() {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    box-shadow: 0px 2px 4px #15152233;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
  }

  &__img {
    width: 72px;
    height: 72px;
    margin: 0 auto 10px;
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__ttl {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 20px;
  }

  &__info {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 15px;
    margin-right: 10px;
    fill: $brand-primary;
  }

  &__btn-more {
    margin: 0;
    width: 100%;
  }
}
