.c-drop-template {
  &__title {
    font-weight: 500;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    min-height: 50px;
    align-items: center;
    display: flex;
    padding: 0 20px;

    .select2-selection__rendered & {
      height: 20px;
      min-height: 20px;
    }
  }

  &__description {
    display: block;
    font-size: 12px;
    font-family: $font-family-base;
    color: #4d4d4f;
    margin-bottom: 5px;
  }

  &__flag {
    width: 50px;
    margin-right: 10px;
    float: left;
  }
}
