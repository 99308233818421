.v-card {
  display: flex;
  background-color: $gray;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 4px #15152233;
  max-width: 500px;

  &--cover {
    width: 100px;
    height: 100px;
    display: block;
    font-size: 70px;
    margin-right: 20px;
    color: #bbb;
  }

  &--name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    color: $neutral-black;
  }

  &--office {
    font-size: 16px;
    margin-bottom: 0;
    color: $neutral-black;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    &--cover {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
