.s-actions {
  background-color: $gray;
  min-height: calc(100vh - 60px);
  padding-bottom: 30px;
  font-family: $font-family-base;

  &--header {
    background-color: $brand-dark;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-top: 63px;
    margin-bottom: 20px;

    a {
      font-family: $font-family-base;
      opacity: 0.5;
      color: #fff;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 0;
      opacity: 0.8;
    }
    span {
      width: 17px;
      font-size: 10px !important;
    }
  }

  ul {
    list-style: disc;
    margin-left: 30px;

    li {
      p {
        margin-bottom: 10px;
      }
    }
  }
}
