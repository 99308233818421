.o-input-mask {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &--search {
    &:before {
      width: 17px;
      height: 17px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17'%3E%3Cpath data-name='Icon material-search' d='M12.15 10.7h-.77l-.27-.27a6.33 6.33 0 10-.68.68l.26.27v.77L15.55 17 17 15.55zm-5.83 0a4.37 4.37 0 114.37-4.38 4.37 4.37 0 01-4.37 4.37z' fill='%23878787'/%3E%3C/svg%3E");
    }
  }

  .o-input {
    padding-left: 35px;
  }
}
