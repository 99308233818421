.u-sr {
  /*
   * Hide only visually, but have it available for screenreaders: h5bp.com/v
   */
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.u-text-center {
  text-align: center;
}

.u-left-auto {
  margin-left: auto;
}

.u-right-auto {
  margin-left: auto;
}

.u-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-mw {
  &-300 {
    max-width: 300px;
  }

  &-380 {
    max-width: 380px;
  }

  &-400 {
    max-width: 400px;
  }
}

.u-w {
  &-200 {
    width: 200px;
  }
}

.u-desk {
  @include below(1024px) {
    display: none;
  }
}

.u-mob {
  @include above(1025px) {
    display: none;
  }
}

.u-cursor {
  &-default {
    cursor: default;
  }
}

.u-ttn {
  text-transform: none;
}

.u-color {
  &-dark-blue {
    color: $brand-primary;
  }

  &-red {
    color: $tomato;
  }

  &-white {
    color: #fff;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }

  &-black {
    color: $neutral-black;
    &:hover,
    &:active,
    &:focus {
      color: $neutral-black;
    }
  }
}

.u-bg {
  &-white {
    background-color: #fff;
  }

  &-lightgray {
    background-color: $light-gray;
  }
}

$spaceCount: 0;
$sides: (top, bottom, left, right, x, y);
@while $spaceCount < 200 {
  @each $side in $sides {
    @if $side == x {
      .u-mx-#{$spaceCount} {
        margin-left: #{$spaceCount}px !important;
        margin-right: #{$spaceCount}px !important;
      }
      .u-px-#{$spaceCount} {
        padding-left: #{$spaceCount}px !important;
        padding-right: #{$spaceCount}px !important;
      }
    } @else if $side == y {
      .u-my-#{$spaceCount} {
        margin-top: #{$spaceCount}px !important;
        margin-bottom: #{$spaceCount}px !important;
      }
      .u-py-#{$spaceCount} {
        padding-top: #{$spaceCount}px !important;
        padding-bottom: #{$spaceCount}px !important;
      }
    } @else {
      .u-m#{str-slice($side, 0, 1)}-#{$spaceCount} {
        margin-#{$side}: #{$spaceCount}px !important;
      }
      .u-p#{str-slice($side, 0, 1)}-#{$spaceCount} {
        padding-#{$side}: #{$spaceCount}px !important;
      }
    }
  }
  //Ajustar conforme necessidade,
  //quanto menor número, maior o número de repetições e consequentemente
  //maior o tamanho da folha de estilos
  $spaceCount: $spaceCount + 5;
}

.u-divider {
  border-bottom: 1px solid #ccc;
  display: block;
  width: 100%;
  margin: 40px 0;
}

.u-display {
  &--none {
    display: none;
  }
  &--block {
    display: block;
  }
}

.u-size {
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-16 {
    font-size: 16px;
  }
  &-18 {
    font-size: 18px;
  }
}

.u-bold {
  font-weight: bold;
}

.u-bb {
  border-bottom: 1px solid #e5e5e5;
}

.alert-success {
  background-color: $brand-dark;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}

.modal-share {
  padding: 80px 40px 56px;
  text-align: center;

  &-title {
    font-size: 24px;
    font-weight: bold;
    color: $neutral-black;
    margin-bottom: 40px;

    &__success {
      color: #48cb11;
      margin-bottom: 90px;
    }
  }

  &-social {
    margin: 0 auto 56px;

    &__icon {
      & + .modal-share-social__icon {
        margin-left: 20px;
      }
    }
  }

  &-success {
    font-size: 90px;
    color: #48cb11;
    margin-bottom: 24px;
  }
}

.modal-backdrop {
  z-index: 100;
}

.modal-footer {
  justify-content: center;
  padding-top: 20px;
}

.u-justify-text {
  text-align: justify;
  text-justify: inter-word;
}
