a {
  color: $link-color;
  text-decoration: none;
  transition: all 0.2s ease-out;

  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 & {
    color: inherit;
  }

  &:hover,
  &:focus {
    color: $link-hover-color;
  }

  &:active {
    /* stylelint-disable-line */
  }

  &:visited {
    // Filling this one is good UX.
  }
}
