.c-modal {
  position: fixed;
  background-color: rgba(#333, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20;
  transition: 0.3s ease-out;
  visibility: hidden;
  opacity: 0;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      width: 15px;
      height: 1px;
      background-color: #777;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
    }

    &:before {
      transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
    }

    &:after {
      transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
    }
  }

  &__lbl {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }

  &__lightbox {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    overflow-y: auto;

    &--large {
      width: 90%;
      height: 90%;

      @media (min-width: 1024px) {
        width: 70%;
        max-width: 550px;
        max-height: 90%;
        height: auto;
      }
    }
  }

  &__content {
    overflow-y: auto;
    height: 90%;
    padding: 0 10px 15px 0;
  }

  &__header {
    padding-bottom: 10px;
  }

  &__body {
    padding: 10px 0;

    &:before,
    &:after {
      content: '';
      background-color: #d9d9d9;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }

    &:before {
      margin-top: -10px;
    }
  }

  &__footer {
    padding: 10px 0 0;
  }
}
