.c-aside {
  width: 100%;

  &--hidden-mobile {
    @include below(1024px) {
      background-color: #fff;
      position: fixed;
      z-index: 10;
      width: 100%;
      left: 0;
      bottom: -100%;
      transition: 0.4s ease-out;

      .aside-active & {
        bottom: 0;
      }
    }
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;

    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 2px;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #fff;
      border-radius: 25px;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__content {
    border-radius: 8px;
    padding: 15px;
    position: relative;

    &--primary {
      background-color: $brand-primary;

      *:not(input, select, option) {
        color: #fff;
      }

      hr {
        background-color: rgba(#fff, 0.5);
      }
    }

    &--gray {
      background-color: $light-gray-2;
    }
  }

  .select2-container * {
    color: #666;
  }

  &__ttl {
    margin-bottom: 15px;

    @include desktop() {
      margin-bottom: 30px;
    }
  }

  &__action-container {
    padding: 20px 0;
    display: flex;
    justify-content: space-around;

    @include below(1024px) {
      padding: 20px 15px;
    }

    .o-btn {
      width: calc(50% - 5px);
      padding: 15px 0;
    }
  }
}
