.o-bg-effect {
  position: fixed;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s 0.2s ease-out, visibility 0.1s;

  &--lightbox {
    background-color: rgba(#333, 0.7);
  }

  &.active {
    visibility: visible;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
