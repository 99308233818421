.c-card-blog {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__wrapper {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    box-sizing: border-box;
    display: inline-table;
    flex: 0 0 auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__date {
    font-size: 12px;
    font-family: $font-family-base;
    color: $text-color;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      padding-left: 0;
      margin-top: 5px;
    }
  }

  &__title {
    font-size: 24px;
    font-family: $font-family-base;
    color: $bg-menu;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  &__resume {
    font-size: 14px;
    font-family: $font-family-base !important;
    color: $text-color;
    width: 100%;

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }

  a {
    color: $bg-menu !important;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px !important;
    font-family: $font-family-base !important;
    text-decoration: none !important;
  }
}
