.o-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;

  &--buttons {
    display: flex;
    justify-content: space-between;
  }
}
