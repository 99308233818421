.o-cards {
  display: grid;
  grid-gap: 20px;

  @media (min-width: 1680px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1680px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1124px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(1, 1fr);
  }

  margin-bottom: 50px;
  font-family: $font-family-base !important;

  &--col-2 {
    grid-template-columns: repeat(1, 1fr);

    @include desktop() {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .o-card {
    overflow: hidden;
    padding-bottom: 60px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    background-color: #fff;
    font-family: $font-family-base !important;
    box-shadow: 0px 2px 4px #15152233;

    &--container {
      display: block;
      height: 200px;
      overflow: hidden;
      position: relative;
    }

    &--info {
      padding: 24px;
      margin-bottom: 4px;

      p {
        margin: 0;
      }

      &-title {
        font-size: 16px;
        line-height: 22px;
        color: $bg-menu;
        font-weight: 600;
        margin-bottom: 20px;
        height: 45px;
        overflow: hidden;

        > a {
          font-size: 16px;
          line-height: 22px;
          color: $bg-menu;
          font-weight: 600;
          margin-bottom: 20px;
          height: 45px;
          overflow: hidden;
          text-decoration: none;
        }
      }

      &-calendar {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    &--inline {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--image {
      width: 100%;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
      object-position: center;
    }

    &--footer {
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      padding: 0 20px;
    }

    &--buttons {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .card-footer {
    background-color: #fff;

    .o-btn {
      position: absolute;
      bottom: 20px;
      right: 0;
      left: 0;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}

.card-radius {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.o-card {
  &--arrow {
    position: absolute;
    right: 20px;
    top: 25px;
  }
}

.card-footer {
  height: 130px;
}
