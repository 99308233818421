.s-highlights {
  background-color: $brand-primary;
  position: relative;
  width: 100%;
  padding: 40px 0 20px;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 500px;
    top: -260px;
    left: 0;
    background-image: url('/images/cards/wave.svg');
    background-size: cover;
    z-index: -1;

    @media (max-width: 768px) {
      height: 160px;
      top: -100px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 500px;
    bottom: -260px;
    left: 0;
    background-image: url('/images/cards/wave.svg');
    background-size: cover;
    transform: rotateX(180deg) rotateY(180deg);
    z-index: -1;

    @media (max-width: 768px) {
      height: 160px;
      bottom: -100px;
    }
  }

  &--wave {
    fill: $brand-primary;
  }

  &--title {
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin-bottom: 48px;
    margin-top: -80px;

    @media (max-width: 768px) {
      margin-top: -40px;
    }
  }

  &--pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    button + button {
      margin-left: 20px;
    }
  }

  .o-cards {
    .o-card {
      margin: 20px;
    }
  }

  .slick-initialized .slick-slide {
    margin: 0 10px;
    box-sizing: border-box;
  }
}
