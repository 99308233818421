.s-about {
  background-color: #f6f6f6;
  min-height: 70vh;
  padding-bottom: 40px;

  .container {
    margin-top: 40px;
    background-color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 700px;
  }

  &--title {
    font-size: 25px;
    font-family: $font-family-base;
    margin: 20px 0;
    font-weight: bold;
    color: $bg-menu;
  }

  &--description {
    font-size: 16px;
    color: $graphite;
    font-family: $font-family-base;

    &-small {
      font-size: 14px;
    }
  }

  &--subtitle {
    font-size: 22px;
    font-family: $font-family-base;
    margin: 20px 0;
    font-weight: bold;
    color: $bg-menu;
  }

  h3 {
    font-size: 18px;
    font-family: $font-family-base;
    margin: 20px 0;
    font-weight: bold;
    color: $bg-menu;
  }

  &--cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--card {
    box-sizing: border-box;
    flex: 0 0 31%;
    background-color: $bg-menu;
    color: #fff;
    line-height: 100px;
    text-align: center;
    margin-bottom: 4%;
    transition: background-color 1s ease;
    font-family: $font-family-base;

    &:hover {
      cursor: pointer;
      background-color: darken($bg-menu, 10%);
    }

    @media (max-width: 990px) {
      flex: 0 0 48%;
    }

    @media (max-width: 580px) {
      flex: 0 0 100%;
    }
  }
}
