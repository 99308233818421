.o-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  display: block;
  margin: 0 auto;

  @include above(1200px) {
    padding: 0;
  }

  &--800 {
    max-width: 800px;
  }
}
