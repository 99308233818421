.c-new-swal {
  text-align: left;

  &--popup {
    transform: translateY(50px);
    animation: fadeInDown;
    animation-duration: 0.5s;
  }

  .swal2-cancel {
    color: $brand-primary !important;
    transition: 0.2s ease-out;
  }

  p {
    margin-bottom: 5px;
    text-align: left;
  }

  .o-ttl {
    color: $brand-primary;
    text-align: left;
    font-size: 16px;
  }
}

@keyframes fadeInDown {
  from {
    transform: translateY(0);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateY(50px);
    opacity: 1;
    visibility: visible;
  }
}
