.o-badges {
  font-size: 13px;
  background-color: $bg-badges;
  border: 1px solid $border-badges;
  color: $color-badges;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  text-transform: lowercase;

  &--border {
    &-color {
      &-dark {
        border: 1px solid #333;
      }
    }
    &-radius {
      &-rect {
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
      }
    }
  }
}
