.c-highlights {
  position: relative;
  padding: 20vw 0 30vw;
  z-index: 1;

  @include desktop() {
    padding: 7vw 0 10vw;
  }

  &:before,
  &:after {
    content: '';
    background-color: $brand-primary-400;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1440 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ff51a0' d='M0 192l80-16c80-16 240-48 400-53.3 160-5.7 320 16.3 480 16 160 .3 320-21.7 400-32l80-10.7v224H0z'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: bottom;
    mask-size: cover;
    position: absolute;
    width: 100%;
    height: 55vw;
    left: 0;
    z-index: -1;

    @include desktop() {
      height: 30vw;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1440 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ff51a0' d='M0 192l80-16c80-16 240-48 400-53.3 160-5.7 320 16.3 480 16 160 .3 320-21.7 400-32l80-10.7v224H0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      background-color: transparent;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
    transform: rotateZ(180deg);
  }

  &__content {
    background-color: $brand-primary-400;
    padding: 20px 0;

    @include desktop() {
      padding: 30px 0;
    }
  }

  &__ttl {
    color: #fff;
    font-size: 28px;
    text-align: center;

    @include desktop() {
      font-size: 32px;
    }
  }
}
