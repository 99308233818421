.pagination {
  .page-link {
    color: $bg-menu;
  }

  .page-item {
    height: 37px;

    &.active .page-link {
      background-color: $brand-dark;
      border-color: $brand-dark;
      padding-bottom: 10px;
    }

    &.disabled .page-link {
      padding-bottom: 11px;
    }
  }
}
