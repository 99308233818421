.dropdown {
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
  padding: 0;

  .dropdown-toggle {
    width: 100%;
  }

  &-item {
    text-transform: capitalize !important;
  }
}

.select2-container--classic .select2-selection--single {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  line-height: 36px !important;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  height: 36px !important;
}
.select2-container .select2-selection--single {
  height: 38px !important;
}

.select2-container {
  width: 100% !important;
}

.select2-container.error {
  padding-bottom: 20px;
  position: relative;

  .select2-choice {
    border: 1px solid #e34326;
  }

  &:after {
    content: 'Campo obrigatório';
    position: absolute;
    bottom: -5px;
    left: 0;
    font-size: 12px;
  }
}
