.c-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    content: '';
    width: calc(25% - 10px);
    height: 0;
  }

  &__item {
    width: calc(50% - 10px);
    height: 160px;
    margin-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.3s ease-out;
    opacity: 1;
    visibility: visible;

    @include above(600px) {
      width: calc(33.333% - 10px);
    }

    @include above(1024px) {
      width: calc(25% - 12px);
      height: 280px;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
      height: 0;
    }

    &--last {
      height: 0;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
  }

  &__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    transition: opacity 0.3s 0.4s ease, filter 0.2s ease-out;

    &:hover {
      filter: brightness(80%);
    }
  }
}
