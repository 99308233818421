.c-upload-zone {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 30px;

  &--mob-center {
    @include below(1024px) {
      margin: 0 auto;
      align-items: center;
      text-align: center;
    }
  }

  &--center {
    margin: 0 auto;
    align-items: center;
    text-align: center;
  }

  &__container {
    max-width: 160px;
  }

  &__ttl {
    font-size: 16px;
    font-weight: bold;
    color: $neutral-black;
    margin: 0 0 15px;
  }

  &__sbttl {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 15px;
  }

  &__img-wrapper {
    position: relative;
    display: inline-flex;

    &:after {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath data-name='Icon ionic-md-add-circle' d='M14 0a14 14 0 1014 14A14 14 0 0014 0zm7.2 15.413h-5.787v5.788h-2.826v-5.788H6.798v-2.826h5.788V6.798h2.827v5.788h5.788z' fill='%231dc7b6'/%3E%3C/svg%3E");
      bottom: 15px;
      right: 0;
      background-color: #fff;
      border-radius: 50%;
    }

    img,
    svg {
      width: 115px;
      max-width: 100%;
      margin: 0 0 15px;
    }
  }

  &__input {
    display: none;
  }

  &__info {
    font-size: 12px;
    color: #6f7779;
  }

  .cr-slider-wrap {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: calc(50% - 5px);
      transform: translateY(-50%);
      font-size: 22px;
      font-weight: bold;
    }

    &:before {
      content: '-';
      left: -25px;
    }

    &:after {
      content: '+';
      right: -25px;
    }
  }
}
