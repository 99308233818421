.s-presence {
  min-height: 80vh;

  .subscribe-user {
    @media (max-width: 768px) {
      p + p {
        margin-top: 10px;
        line-height: 1.2;
      }
    }
  }
}
