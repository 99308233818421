.dz-remove {
  color: $bg-menu;

  &:hover {
    color: darken($bg-menu, 10%);
  }
}

.dropzone.dz-started .dz-message {
  display: block !important;
}

.dropzone .dz-preview .dz-image {
  margin: 0 auto 10px;
}

.dropzone {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .dz-preview {
    width: 50%;
    margin: 0;
    padding: 10px;
  }
}
