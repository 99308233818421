.c-list-users {
  margin-top: 15px;

  &__item {
    display: flex;
    padding: 15px 0;
    position: relative;

    &:after {
      content: '';
      width: calc(100% - 90px);
      background-color: #ccc;
      height: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:last-child:after {
      width: 100%;
    }
  }

  &__img-wrapper {
    width: 70px;
    flex: 0 0 auto;
  }

  &__img {
    width: 100%;
    max-width: 65px;
    max-height: 65px;
  }

  &__content-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__ttl {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 2px;
  }

  &__info {
    font-size: 12px;
    line-height: 1.4;
  }

  &__ttl,
  &__info {
    word-break: break-all;
  }
}
