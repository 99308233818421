.c-policy-bar {
  position: fixed;
  bottom: -100%;
  background-color: $brand-primary;
  left: 0;
  right: 0;
  padding: 15px 0;
  color: #fff;
  z-index: 10;
  box-shadow: 0px -2px 4px #15152233;
  transition: 1s ease;

  .show-policy & {
    bottom: 0;
    transition: 2s ease;
  }

  &__link {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #FFF;
    }
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:nth-child(1) {
      width: 70%;
    }
  }
}
