ul,
ol {
  padding: 0;
  list-style-position: inside;
  list-style: none;
}

.o-list {
  width: 100%;

  &--item {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-bottom: 1px solid #f1f1f1;
    text-decoration: none;
    font-weight: bold;
    color: $text-color;
    font-family: $font-family-base;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: #fafafa;
      color: $text-color;
    }

    &-title {
      font-size: 16px;
    }
  }
}
