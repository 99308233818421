.c-post {
  &:before,
  &:after {
    content: '';
    clear: both;
  }

  p {
    margin: 0 0 20px;
    text-align: left;
  }

  img {
    width: 100%;
    height: 100%;
    margin: 15px 0;

    @include desktop() {
      margin: 0 15px 15px;
      width: auto;
      height: auto;
    }
  }

  ul {
    list-style: disc;
    padding-left: 15px;
  }

  ol {
    list-style: decimal;
    padding-left: 15px;
  }

  iframe {
    width: 100%;
    max-width: 100%;
  }

  table {
    max-width: 100%;
  }
}
