// Colors
$brand-primary: #d7171e;
$brand-primary-400: #cf3438;
$brand-secondary: #232020;
$brand-tertiary: #d9e0e6;

$neutral-black: #151522;
$neutral-gray: #878787;
$brand-gray: #e6e6e6;
$brand-dark-gray: #b3b3b3;
$button-dark: #383838;
$tomato: #e34326;
$black: #000;
$white: #fff;
$gray: #f1f3f6;
$yellow: #f9c55d;
$green: #28a745;
$graphite: #6f7779;
$light-gray: #f0f0f0;
$light-gray-2: #f5f5f5;

// Menu Suzano
$bg-menu: $brand-primary;
$bg-menu-white: #fff;
$brand-dark: $brand-secondary;
$slick-active: #f1f2f2;
$slick-inactive: #dcddde;

// Admin
$brand: '';
$bgpage: #fff;
$sidebarColor: $bg-menu;
$sidebarBorder: $brand-dark;
$topbarColor: $brand-dark;
$button-subscribed: #151522;
$button-subscribed-color: #fff;

// title
$line-orange: #fba700;
$line-height: 7.8px;
$line-width: 40px;
$title-color: #333;
$text-color: #4d4d4f;

// Objects
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 10%);
$selection-bg-color: $link-color;
$selection-text-color: #fff;
$btn-color-primary: $link-color;
$bg-badges: transparent;
$border-badges: #f0f0f0;
$color-badges: #444;

// Typography
$font-family-base: 'Roboto', sans-serif;
$font-family-auxiliar: 'Raleway', sans-serif;
$font-size-default: 14px;

// Screen Sizes
$screen-sm: 767px;
$screen-md: 1023px;
$screen-lg: 1279px;

$default-transition: all 0.2s ease-in-out;
