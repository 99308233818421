.s-share {
  &--buttons {
    display: flex;
    align-items: center;

    a {
      font-size: 20px;
    }

    a + a {
      margin-left: 20px;
    }
  }
}
