.s-blog {
  min-height: 70vh;

  .container {
    margin-top: 80px;

    a {
      color: $bg-menu !important;
      cursor: pointer;
      text-decoration: underline;
      font-size: 14px !important;
      font-family: $font-family-base !important;
    }
  }

  ul {
    list-style: disc;
    margin-left: 20px;
  }

  &--fullcover {
    width: 100%;
    max-width: 500px;
  }

  p,
  span {
    font-family: $font-family-base !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .fa {
    font-family: 'Font Awesome 5 Free' !important;
  }

  @media (max-width: 900px) {
    img {
      width: 100%;
    }

    td {
      display: block;
      width: 100% !important;
    }
  }
}
