fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  @extend .u-sr;
}

textarea {
  resize: vertical;
  min-height: 3em;
}

.js-thumb {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

input::placeholder {
  color: #c1c1c1 !important;
}
