.o-link {
  font-size: 16px;
  color: $neutral-black;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }

  svg {
    max-width: 20px;
    max-height: 20px;
    margin: 0 10px;
    stroke: $brand-primary;
  }

  &--white {
    &:hover {
      color: #fff;
    }
    svg {
      stroke: #fff;
    }
  }

  &--small {
    svg {
      max-width: 15px;
      max-height: 15px;
    }
  }
}
