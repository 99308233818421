// These should have the same values as the meta viewport tag.
@viewport {
  width: device-width;
  zoom: 1; /* stylelint-disable-line */
  // stylelint is disabled for this rule because zoom can have a trailing zero
}

::selection {
  background: $selection-bg-color;
  text-shadow: none;
  color: $selection-text-color;
}

html {
  width: 100vw;
  overflow: hidden;
}

html,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-base !important;
  color: $text-color;
  outline-color: $brand-primary;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $font-family-base !important;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
