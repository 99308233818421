.c-popover {
  position: relative;

  &__link {
    display: block;
    color: #878787;
    font-size: 15px;

    &:hover {
      cursor: pointer;
      color: #151522;
      text-decoration: none;
    }
  }

  &__hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 200px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 1px 4px #000026;
    z-index: 10;
    right: 0;
    font-size: 12px;
    transition: 0.2s ease-out;

    .c-popover:hover & {
      opacity: 1;
      visibility: visible;
    }

    p {
      margin: 0 0 5px;
    }
  }
}
