/*
Common 
*/

.c-form-steps,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.tabcontrol a {
  outline: 0;
}

.c-form-steps ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.c-form-steps ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}

/* Accessibility */
.c-form-steps > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.c-form-steps > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

/*
Wizard
*/

.c-form-steps > .steps {
  display: none;
}

.c-form-steps.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}

.c-form-steps > .steps .number {
  font-size: 1.429em;
  display: none;
}

.c-form-steps > .steps > ul > li {
  width: 25%;
}

.c-form-steps > .steps > ul > li,
.c-form-steps > .actions > ul > li {
  float: left;
}

.c-form-steps.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.c-form-steps > .steps a,
.c-form-steps > .steps a:hover,
.c-form-steps > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.c-form-steps > .steps .disabled a,
.c-form-steps > .steps .disabled a:hover,
.c-form-steps > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
}

.c-form-steps > .steps .current a,
.c-form-steps > .steps .current a:hover,
.c-form-steps > .steps .current a:active {
  background: #2184be;
  color: #fff;
  cursor: default;
}

.c-form-steps > .steps .done a,
.c-form-steps > .steps .done a:hover,
.c-form-steps > .steps .done a:active {
  background: #9dc8e2;
  color: #fff;
}

.c-form-steps > .steps .error a,
.c-form-steps > .steps .error a:hover,
.c-form-steps > .steps .error a:active {
  background: #ff3111;
  color: #fff;
}

.c-form-steps > .content {
  background: transparent;
  display: block;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  width: 100%;
}

.c-form-steps.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}

.c-form-steps > .content > .body {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
}

.c-form-steps > .content > .body ul {
  list-style: disc !important;
}

.c-form-steps > .content > .body ul > li {
  display: list-item;
}

.c-form-steps > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.c-form-steps > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.c-form-steps.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

.c-form-steps > .actions > ul {
  display: inline-block;
  text-align: right;
}

.c-form-steps > .actions > ul > li {
  margin: 0 0.5em;
}

.c-form-steps.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.c-form-steps > .actions a,
.c-form-steps > .actions a:hover,
.c-form-steps > .actions a:active {
  background: #2184be;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.c-form-steps > .actions .disabled a,
.c-form-steps > .actions .disabled a:hover,
.c-form-steps > .actions .disabled a:active {
  background: #eee;
  color: #aaa;
}

.c-form-steps > .loading {
}

.c-form-steps > .loading .spinner {
}

/*
Tabcontrol
*/

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;

  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

.c-form-steps {
  &__input-container {
    background-color: $light-gray-2;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px #15152233;
    margin: 5px;
  }

  &__sbttl {
    font-weight: normal;
    font-size: 15px;
    margin-bottom: 30px;
  }

  .o-label {
    color: $neutral-black;
    font-size: 14px;
  }
}
