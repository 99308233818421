.c-pagination {
  background-color: $light-gray-2;
  margin: 0 auto;
  padding: 21px 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;

  &.is-hidden {
    display: none;
  }

  @include desktop() {
    width: 90%;
    flex-wrap: nowrap;

    &:before {
      content: '';
      width: 100%;
    }
  }

  &__nav {
    margin-left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nav-item {
    padding: 0 5px;
    margin: 0 3px;
    outline: none;
    border: none;
    background-color: transparent;

    &--active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__actions {
    margin-left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;

    @include desktop() {
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  &__btn {
    padding: 12px 29px;
    min-height: 38px;
    vertical-align: middle;
    font-size: $font-size-default;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border-radius: 5px;
    line-height: 1;
    outline: none;
    font-weight: 600;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    background-color: $brand-primary;
    color: #fff;
    border: 1px solid $brand-primary;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}
