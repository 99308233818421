/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 5px;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }
}
.slick-arrow {
  &.slick-hidden {
    display: none;
  }
}

.o-slick-arrow {
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: 70px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  background-color: rgba($brand-primary, 0.4);
  top: 0;
  &--secondary {
    background-color: transparent;
    height: 40px;
    width: 40px;
    top: 120px;
  }
  @media (max-width: $screen-md) {
    display: none;
  }

  &:after {
    content: '';
    background-image: url('../img/icons/arrow.svg');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .u-bg-orange & {
      background-image: url('../img/icons/arrow-blue.svg');
    }
  }

  &--prev {
    left: 0;
    &.o-slick-arrow--secondary {
      left: -15px;
    }
  }

  &--next {
    right: 0;
    &:after {
      transform: rotateY(180deg) translate(-50%, -50%);
      transform-origin: left;
    }
    &.o-slick-arrow--secondary {
      right: -15px;
    }
  }

  &--onthefly {
    background-color: #34b687;
    margin: 0;

    &.o-slick-arrow--prev {
      left: 0;
    }

    &.o-slick-arrow--next {
      left: initial;
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}

.slick-dots {
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  li {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: $slick-inactive;
    border: 2px solid $white;
    margin: 0 3px;
    border-radius: 50px;

    button {
      display: none;
    }

    &.slick-active {
      background-color: $slick-active;
    }
  }
}
