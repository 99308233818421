.c-faq {
  padding-bottom: 100px;

  &__ttl {
    color: $bg-menu;
    font-weight: bold;
    padding: 30px 30px 30px 0;
    font-family: $font-family-base;
    font-size: 16px;
    position: relative;
    border-top: 1px solid #ccc;
    cursor: pointer;

    &:first-child {
      border-top: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 35px;
      transition: all 0.5s ease-in-out;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath d='M325.6 79.4a15 15 0 0 0-21.2 0L165 218.78 25.6 79.39a15 15 0 0 0-21.2 21.22l150 150a15 15 0 0 0 21.21 0l150-150a15 15 0 0 0 0-21.22z'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-repeat: no-repeat;
      background-color: $brand-primary;
      width: 30px;
      height: 25px;
    }

    &.active {
      &:after {
        transform: rotateX(-180deg);
      }

      + .c-faq__text {
        max-height: 10000px;
        transition: max-height 10s ease;
      }
    }
  }

  &__text {
    transition: max-height 4s ease;
    max-height: 0px;
    overflow-y: hidden;
  }

  a {
    color: black;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: $bg-menu;
    color: #fff;
    padding: 20px 0;

    a {
      color: #fff;
      padding-left: 20px;
    }

    .container {
      display: flex;
      align-items: center;
    }

    &--title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &--contact {
      font-size: 14px;
      padding-left: 20px;
      margin-bottom: 10px;
    }

    @media (max-width: 768px) {
      .container {
        flex-direction: column;
      }

      &--contact {
        padding-left: 0;
      }

      &__column {
        &-one {
          width: 100%;
          margin-bottom: 20px;
        }
        &-two {
          width: 100%;
        }
      }
    }
  }
}
