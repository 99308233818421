.o-flex {
  display: flex;

  &--fww {
    flex-wrap: wrap;
  }

  &--gap {
    gap: attr(data-gap);
  }

  &--fd {
    &-c {
      flex-direction: column;
    }

    &-c-sm {
      @include below(768px) {
        flex-direction: column;
      }
    }
  }

  &--jc {
    &-ct {
      justify-content: center;
    }
    &-ct-mob {
      @include below(1024px) {
        justify-content: center;
      }
    }

    &-sa {
      justify-content: space-around;
    }

    &-sb {
      justify-content: space-between;
    }

    &-fs {
      justify-content: flex-start;
    }

    &-fe {
      justify-content: flex-end;
    }
  }

  &--ai {
    &-ct {
      align-items: center;
    }
  }
}
