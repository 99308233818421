.c-steps-page {
  display: flex;

  &--rfc {
    flex-wrap: wrap;
  }

  &__ttl-container {
    width: 100%;
  }

  &__ttl {
    margin-bottom: 40px;
    font-size: 24px;
    color: $brand-primary;
  }

  &__sbttl {
    max-width: 320px;
    width: 100%;
    display: block;
  }

  &__steps-container {
    width: 65%;

    @include below(1024px) {
      width: 100%;
    }
  }

  &__fixed-column {
    width: 35%;

    @include below(1024px) {
      width: 100%;
    }
  }
}
