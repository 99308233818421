.carousel {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }

  &-caption {
    left: 10%;
    bottom: 50px;
    max-width: 500px;
    text-align: left;

    p {
      font-size: 32px;
      line-height: 48px;
      color: #fff;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 56px;
      line-height: 72px;
      font-weight: bold;
      font-family: $font-family-base;
      margin-bottom: 20px;
      color: #fff;
    }

    @media (max-width: 980px) {
      top: 35%;
    }

    @media (max-width: 780px) {
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      min-height: 500px;

      p {
        font-size: 32px;
      }

      h2 {
        font-size: 22px;
        line-height: 26px;
      }

      a {
        margin: 20px auto;
      }
    }
  }

  &-indicators {
    bottom: -10px;

    li {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin: 0 8px;
    }
  }
}
