.o-pill {
  color: #fff;
  background-color: $brand-primary;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 12px;
}
