.o-textarea {
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 2;
  border: 1px solid #d0d0d0;
  padding: 11px 15px;
  display: block;
  width: 100%;
  height: 150px;
  outline: none;
  &.error {
    border: 1px solid $tomato;
  }
}
