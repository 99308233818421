.c-mid-banner {
  &--flex {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: $font-family-base;
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      background-color: $brand-primary;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.4;
      pointer-events: none;
      z-index: -1;
    }

    .c-mid-banner--flex & {
      @include desktop() {
        width: 50%;

        &:nth-child(even) {
          .c-mid-banner__content:not(.c-mid-banner__content--full) {
            margin-right: auto;
          }
        }

        &:nth-child(odd) {
          .c-mid-banner__content:not(.c-mid-banner__content--full) {
            margin-left: auto;
          }
        }
      }
    }

    &--full {
      .c-mid-banner--flex & {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 400px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include desktop() {
      min-height: 345px;
      align-items: flex-start;
      margin: 0;
    }

    .c-mid-banner--flex & {
      max-width: 600px;
    }

    &--full {
      margin: 0 auto;
      .c-mid-banner--flex & {
        max-width: 1200px;
      }
    }
  }

  &__company {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: auto;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;

    @include desktop() {
      text-align: left;
    }
  }

  &__ttl {
    font-size: 32px;
    line-height: 1;
    text-align: center;

    @include desktop() {
      text-align: left;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 70px;

    @include desktop() {
      align-items: flex-start;
    }

    .o-btn {
      min-width: 240px;
    }
  }
}
