.c-form {
  &__control {
    display: block;
    margin: 20px 0 0;

    .error {
      color: #e34326;
      font-size: 12px;
    }
  }
}
