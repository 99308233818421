.c-dropup {
  position: relative;

  &__button {
    &:after {
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }
  }

  &__list {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 10px 0;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin: 0;
    min-width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s ease-out;
    white-space: nowrap;

    .c-dropup--top & {
      bottom: calc(100% + 5px);
      top: auto;
    }

    .c-dropup.active & {
      opacity: 1;
      visibility: visible;
    }

    &-item {
      padding: 3px 20px;
      transition: 0.2s ease-out;

      &:hover {
        background-color: rgba($brand-primary, 0.3);
      }

      > *,
      > *:hover {
        color: $neutral-black;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
