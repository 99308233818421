.o-checkbox {
  position: relative;
  margin: 0 20px;
  > input {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
      + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-family: inherit;
        font-size: 14px;
        font-weight: 300;
        color: inherit;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 22px;
          height: 22px;
          background: #ffffff;
          border: 1px solid #383838;
          border-radius: 3px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          top: 1px;
          left: 1px;
          background-color: #383838;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='402' width='582' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath fill='%23ffffff' id='svg_1' fill-rule='evenodd' d='m21.652,3.211c-0.293,-0.295 -0.77,-0.295 -1.061,0l-11.181,11.129c-0.293,0.297 -0.771,0.297 -1.062,0l-4.899,-4.989c-0.145,-0.148 -0.335,-0.221 -0.526,-0.222c-0.193,-0.001 -0.389,0.072 -0.536,0.222l-2.165,1.946c-0.144,0.148 -0.222,0.333 -0.222,0.526c0,0.194 0.078,0.397 0.223,0.544l4.94,5.184c0.292,0.296 0.771,0.776 1.062,1.07l2.124,2.141c0.292,0.293 0.769,0.293 1.062,0l14.366,-14.34c0.293,-0.294 0.293,-0.777 0,-1.071l-2.125,-2.14z' clip-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          transition: opacity 0.2s, transform 0.2s;
        }
      }
    }

    &:not(:checked) {
      + label {
        &:after {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    &:checked {
      + label {
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
