.c-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: max-content;

  &--col-30-70 {
    grid-template-columns: repeat(1, 3fr 7fr);
  }

  &--col-35-65 {
    grid-template-columns: repeat(1, 35fr 65fr);
  }

  &--col-40-60 {
    grid-template-columns: repeat(1, 4fr 6fr);
  }

  &--col-50-50 {
    grid-template-columns: repeat(1, 5fr 5fr);
  }

  &--aside {
    @include desktop() {
      grid-template-columns: repeat(1, 24fr 76fr);
    }
  }

  &--cards {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);

    @include above(500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include above(1150px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--cards-4 {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);

    @include above(500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include above(900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include above(1150px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--col-100-mob {
    @include below(1024px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--ais {
    align-items: start;
  }
}
