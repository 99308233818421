.c-intro {
  margin: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__ttl {
    text-align: left;
    font-size: 40px;
    max-width: 400px;
    font-family: $font-family-base;
    font-weight: bold;
    color: $brand-primary;
    margin-bottom: 0;
  }

  &__text {
    text-align: justify;
    font-size: 16px;
    font-family: $font-family-base;
    color: $title-color;
    width: 100%;
    max-width: 552px;
    margin-bottom: 0;

    * {
      font-size: 16px;
    }
  }
}
