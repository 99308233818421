.c-header {
  background-color: #fff;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 20;
  top: 0;
  box-shadow: 0px 2px 4px #15152212;

  @media (max-width: 1024px) {
    .navbar-collapse {
      padding-top: 30px;
    }
  }

  &__logo {
    width: 100px;
    height: 30px;
  }
}
