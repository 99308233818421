.fa-icon {
  &--report {
    margin-right: 10px;
    font-size: 20px;

    &-pending {
      color: #ccc;
    }

    &-ok {
      color: $green;
    }
  }
}
