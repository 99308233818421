// input objects are the only ones that can have cosmetic styles
// because their definition in base would be too complex.
// e.g.: input[type="text"], input[type="password"]...
.o-input {
  &--text,
  &[type='text'] {
    font-size: 14px;
    font-family: $font-family-base;
    line-height: 120%;
    width: 100%;
    border: 1px solid #ccc;
    outline-color: #ccc;
    padding: 8px 5px;
    border-radius: 5px;
  }

  &--faq {
    height: 45px;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  &--file {
    input[type='file'] {
      display: none;
    }

    label {
      width: 100%;
      height: 97px;
      border: 1px solid #ccc;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      background-color: #ccc;
      color: #696969;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 3.5;
      cursor: pointer;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
  }

  &--container {
    width: 200px;
    margin: 30px auto;
    text-align: center;
    position: relative;
  }

  &--thumb {
    width: 150px;
    height: 150px;
  }

  &--fixed {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &--link {
    border: 1px solid $neutral-gray;
    border-radius: 4px;
    position: relative;
    padding: 8px 8px 8px 40px;
    display: block;
    height: 32px;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    outline: none;

    p {
      margin: 0;
      font-size: 14px;
      color: $button-dark;
      line-height: 14px;
      white-space: nowrap;
    }

    &::before {
      position: absolute;
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'><defs><style>.a{fill:rgb(56,56,56);}.b{fill:none;}</style></defs><g transform='translate(-525 -344)'><path class='a' d='M10.206,5.793a4.748,4.748,0,0,1,.011,6.706l-.011.012-2.1,2.1A4.75,4.75,0,0,1,1.389,7.893l1.16-1.16a.5.5,0,0,1,.853.331,5.758,5.758,0,0,0,.3,1.648.5.5,0,0,1-.118.519l-.409.409a2.25,2.25,0,1,0,3.162,3.2l2.1-2.1a2.249,2.249,0,0,0,0-3.182,2.341,2.341,0,0,0-.323-.268A.5.5,0,0,1,7.9,6.9a1.245,1.245,0,0,1,.366-.931l.658-.658a.5.5,0,0,1,.643-.054,4.765,4.765,0,0,1,.641.537Zm4.4-4.4a4.755,4.755,0,0,0-6.717,0l-2.1,2.1L5.782,3.5a4.751,4.751,0,0,0,.653,7.243.5.5,0,0,0,.643-.054l.658-.658A1.245,1.245,0,0,0,8.1,9.1a.5.5,0,0,0-.217-.394,2.341,2.341,0,0,1-.323-.268,2.249,2.249,0,0,1,0-3.182l2.1-2.1a2.25,2.25,0,1,1,3.162,3.2l-.409.409a.5.5,0,0,0-.118.519,5.758,5.758,0,0,1,.3,1.648.5.5,0,0,0,.853.331l1.16-1.16a4.755,4.755,0,0,0,0-6.717Z' transform='translate(533.001 352)'/><rect class='b' width='16' height='16' transform='translate(533 352)'/></g><rect class='b' width='8' height='32' transform='translate(24)'/><rect class='b' width='8' height='32'/><rect class='b' width='32' height='8'/><rect class='b' width='32' height='8' transform='translate(0 24)'/></svg>");
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      background-color: #ccc;
    }
  }

  &--cover {
    width: 60px;
    height: 60px;

    input[type='file'] {
      display: none;
    }

    label {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      background-color: #cccdde;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 120%;
      cursor: pointer;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
  }

  &--textarea {
    font-size: 14px;
    font-family: $font-family-base;
    line-height: 120%;
    width: 100%;
    height: 120px;
    border: 1px solid #ccc;
    outline-color: #ccc;
    padding: 8px 5px;
    border-radius: 5px;
  }
}

.o-input-select {
  font-size: 14px;
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  padding: 6px 5px;
  border-radius: 5px;
  margin: 0;
  outline: none;
}

input[type='select']:focus {
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  padding: 8px 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin: 0;
}
