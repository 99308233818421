.o-custom-select {
  position: relative;
  display: inline-block;
  background-color: $light-gray;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    top: 12px;
    right: 15px;
    pointer-events: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 451.8 451.8'%3E%3Cpath fill='%2305194e' d='M226 354.7c-8.2 0-16.3-3-22.4-9.3L9.3 151.2A31.6 31.6 0 1154 106.4l172 172 171.8-172a31.6 31.6 0 0144.8 44.8L248.3 345.4a31.5 31.5 0 01-22.4 9.3z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .o-select {
    appearance: none;
    padding: 9px 45px 10px 13px;
    color: $brand-primary;
    font-size: 14px;
    background-color: transparent;
    border-radius: 0;
    border-color: $brand-primary;
    outline: none;
    text-transform: uppercase;

    @media (min-width: $screen-sm) {
      width: 210px;
    }
  }

  & + & {
    margin-left: 6px;
  }
}

.select2-container-multi {
  min-height: 40px !important;
}

.select2-search-choice {
  padding: 10px 10px 10px 22px !important;
  font-family: $font-family-base !important;
  line-height: 1.3 !important;
}

.select2-container-multi .select2-search-choice-close {
  top: 12px !important;
  left: 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.select2-container .select2-choice {
  height: 40px !important;
  padding: 7px 0 0 15px !important;
  color: #666 !important;
}

.select2-container .select2-choice .select2-arrow {
  padding-top: 5px;
}

.select2-container-multi .select2-choices .select2-search-field input {
  color: #666 !important;
  font-size: 14px;
  padding-left: 15px;
}

.select2-results .select2-result-label {
  font-size: 14px;
  font-family: $font-family-base !important;
}
