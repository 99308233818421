.o-btn {
  vertical-align: middle;
  font-size: $font-size-default;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border: none;
  border-radius: 5px;
  line-height: 1;
  outline: none;
  padding: 15px 30px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  white-space: nowrap;

  &:hover {
    color: #fff;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    padding: 12px 30px;
    margin: 0;
    justify-content: center;

    svg {
      max-width: 15px;
      max-height: 15px;
      margin-right: 10px;
    }
  }

  &--icon-right svg {
    margin-left: auto;
  }

  &--icon-left svg {
    margin-right: auto;
  }

  &--options {
    background-color: $brand-primary;
    color: #fff;
    border: 1px solid $brand-primary;

    &:visited,
    &:active,
    &:focus {
      background-color: $brand-primary;
      color: #fff;
      border: 1px solid $brand-primary;
      outline: none;
      box-shadow: none;
    }

    &:hover {
      border: 1px solid $brand-primary-400;
      background-color: $brand-primary-400;
      color: #fff;
    }
  }

  &--primary {
    background-color: $brand-primary;
    color: #fff;
    border: 1px solid $brand-primary;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-primary-400;
      border-color: $brand-primary-400;
      color: #fff;
    }
  }

  &--secondary {
    background-color: $brand-secondary;
    color: #fff;
    border: 1px solid $brand-secondary;
    margin-right: 10px;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: #fff;
    }
  }

  &--tertiary {
    background-color: $brand-tertiary;
    color: $brand-secondary;
    border: 1px solid $brand-tertiary;
    margin-right: 10px;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: #fff;
    }
  }

  &--white-primary {
    background-color: #fff;
    color: $brand-primary;
    border: 1px solid $brand-primary;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-primary-400;
      border-color: $brand-primary-400;
      color: #fff;
    }
  }

  &--white-secondary {
    background-color: #fff;
    color: $brand-secondary;
    border: 1px solid $brand-secondary;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-secondary;
      border-color: $brand-secondary;
    }
  }

  &--transparent-primary {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      border-color: #fff;
      color: $brand-primary;
    }
  }

  &--delete {
    background-color: #ff3126;
    color: #fff;
    border: 1px solid #ff3126;
    width: 50px;
    padding: 15px;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(#ff3126, 5%);
    }
  }

  &--filter {
    position: relative;
    text-align: left;
    text-transform: none;
    padding: 15px;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;

    @include desktop() {
      padding: 10px 0;
    }

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath data-name='Icon awesome-filter' d='M15.249 0H.751a.751.751 0 00-.53 1.28L6 7.061V13.5a.75.75 0 00.32.614l2.5 1.749a.75.75 0 001.18-.614V7.061l5.78-5.78A.751.751 0 0015.249 0z' fill='%23fff'/%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      @include desktop() {
        right: 0;
      }
    }
  }

  &--more {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $bg-menu;
    color: #fff;

    &:hover {
      background-color: #1bc9ac;
    }

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cpath id='_Color' data-name=' ↳Color' d='M14,8H8v6H6V8H0V6H6V0H8V6h6Z' fill='%23fdfdfd'/%3E%3C/svg%3E%0A");
      margin-right: 15px;
    }
  }

  &--danger {
    background-color: red;
    color: #fff;

    &:visited {
      color: #fff;
    }
  }

  &--social {
    background-color: transparent;
    border: 1px solid $brand-primary;

    .fa {
      color: $brand-primary;
    }

    &:hover {
      background-color: $brand-primary-400;

      .fa {
        color: #fff;
      }
    }
  }

  &--subscribed {
    background-color: transparent;
    color: $brand-primary;
    display: flex;
    justify-content: center;
    box-shadow: none;
    transition: box-shadow 0.2s ease-out, background-color 0.2s linear;
    position: relative;
    overflow: hidden;
    border: 1px solid $brand-primary;

    &:after {
      content: '';
      background-color: #fff;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cg data-name='Icon feather-log-out' fill='none' stroke='%238273e8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath data-name='Caminho 3' d='M6.333 17H2.778A1.778 1.778 0 011 15.222V2.778A1.778 1.778 0 012.778 1h3.555'/%3E%3Cpath data-name='Caminho 4' d='M12.556 13.445L17 9.001l-4.444-4.445'/%3E%3Cpath data-name='Caminho 5' d='M17 9H6.333'/%3E%3C/g%3E%3C/svg%3E");
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      background-color: $brand-primary;
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(21, 21, 34, 0.2);
      color: #fff;
      cursor: pointer;
      box-shadow: none;
      border: 1px solid $brand-primary-400;
      background-color: $brand-primary-400;

      &:after {
        background-color: #fff;
      }
    }
  }

  &--full-sm {
    @include below(1024px) {
      width: 100%;
    }
  }

  &--xs {
    font-size: 13px;
    font-weight: 400;
    padding: 8px;
    text-transform: lowercase;
  }

  &--full {
    width: 100%;
    @include desktop() {
      width: 90%;
    }
  }

  &--download {
    padding-left: 50px;

    span {
      position: relative;
      line-height: 1.3;

      &:after {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg data-name='Icon feather-download' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath data-name='Caminho 2092' d='M15 10.33v3.11A1.56 1.56 0 0113.44 15H2.56A1.56 1.56 0 011 13.44v-3.1'/%3E%3Cpath data-name='Caminho 2093' d='M4.11 6.44L8 10.34l3.89-3.9'/%3E%3Cpath data-name='Caminho 2094' d='M8 10.33V1'/%3E%3C/g%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--disabled {
    background-color: $brand-gray;
    color: $brand-dark-gray;
    border: 1px solid $brand-gray;
    pointer-events: none;

    &:hover,
    &:active,
    &:focus {
      color: $brand-gray;
      background-color: $brand-gray;
    }
  }

  &--border-none {
    border: none;
  }
}

.o-btn-arrow {
  position: absolute;
  bottom: -55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  appearance: none;
  border-radius: 8px;
  width: 30px;
  height: 20px;
  z-index: 10;
  outline: none;
  transition: 0.2s ease-out;
  padding: 0;
  transform: translateX(-50%);

  &:before {
    content: '';
    width: 25px;
    height: 20px;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 20'%3E%3Cpath d='M9.311.276a1 1 0 011.379 1.448L3.019 9H23a1 1 0 110 2H3.051l7.639 7.276a1 1 0 11-1.379 1.448l-8.725-8.31A2 2 0 01.6 8.569z' fill='%23fff'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: $brand-primary;
  }

  &--white:before {
    background-color: #fff;
  }

  &:focus,
  &:active {
    outline: 0;
  }

  &.slick-disabled {
    opacity: 0.6;
    cursor: initial;
  }

  &--prev {
    left: calc(50% - 40px);
    padding-right: 2px;
  }

  &--next {
    left: calc(50% + 40px);
    padding-left: 2px;

    &:before {
      transform: rotateZ(180deg);
    }
  }
}
