html {
  min-height: 100%;
  overflow-y: scroll;
  line-height: 1.4;
  box-sizing: border-box;
  // The body background should go here. We don't need to touch the body.
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  font-size: 14px;
  line-height: 1.71;
}

span {
  font-size: inherit;
}

b,
strong {
  font-weight: 600;
}

label {
  margin-bottom: 0.5rem;
}
