.c-list-filter {
  &__item {
    margin: 20px 0 5px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
  }

  &__child {
    color: #fff;
    font-size: 14px;
    margin: 0 0 0 10px;
    cursor: pointer;
  }
}
