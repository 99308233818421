.c-nav {
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  right: -360px;
  top: 70px;
  width: 360px;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  transition: right 0.2s linear;
  box-shadow: 0px 1px 2px #cccccc;
  border-radius: 0 0 4px 4px;

  .nav-active & {
    right: 0;
  }

  &__row {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }

  &__btn {
    width: 100%;
    margin: 0 5px;
    padding: 15px 20px;
  }
}
