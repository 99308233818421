.c-card-material {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  font-family: $font-family-base !important;
  box-shadow: 0px 2px 4px #15152233;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  opacity: 1;
  visibility: visible;
  transform: scale(1);

  &.animate {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
  }

  &__ttl {
    margin: 0 0 5px;
    color: $brand-primary;
    font-weight: bold;
    font-size: 20px;
  }

  &__action {
    margin-top: auto;
    display: flex;
    justify-content: center;
  }
}
