.c-card-hability {
  overflow: hidden;
  padding: 24px 0 10px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  font-family: $font-family-base !important;
  box-shadow: 0px 2px 4px #15152233;

  &__header,
  &__list {
    padding: 0 24px;
  }

  &__info {
    padding-left: 20px;

    * {
      word-break: break-all;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #f1f3f6;
  }

  &__item-name,
  &__category {
    font-size: 12px;
    font-family: $font-family-base;
    margin-bottom: 5px;
  }

  &__category {
    font-weight: bold;
  }

  &__item-name {
    margin-left: 10px;
  }

  &__image {
    width: 50px;
    height: 50px;
    flex: 0 0 auto;
    border-radius: 50%;
  }

  &__title {
    text-align: center;
    font-size: 25px;
    font-family: $font-family-base;
    margin: 20px 0;
    font-weight: bold;
    color: $bg-menu;
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
    color: $bg-menu;
    font-family: $font-family-base;
    margin-bottom: 5px;
  }

  &__city {
    font-size: 12px;
    font-family: $font-family-base;
    margin-bottom: 5px;
  }

  &__unity {
    font-size: 12px;
    font-family: $font-family-base;
    margin-bottom: 5px;
  }

  &__email {
    font-size: 12px;
    font-family: $font-family-base;
    margin-bottom: 5px;
  }

  &__see-more {
    padding: 15px 0 0;
    font-size: 12px;
    font-weight: bold;
    font-family: $font-family-base;

    &:hover {
      cursor: pointer;
    }
  }
}
