.c-challenge-show {
  padding: 40px 0;

  @include desktop() {
    padding: 70px 0;
  }

  &__grid {
    @include desktop() {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(1, 45fr 55fr);
    }
  }

  &__cover-container {
    position: relative;
    width: 100%;
    max-width: 512px;
    border-radius: 8px;
  }

  &__cover {
    width: 100%;
    height: 100%;
    max-height: 380px;
    display: block;
    border-radius: 10px 10px 8px 8px;

    @include below(1024px) {
      margin: 0 auto;
    }
  }

  &__chip {
    color: $brand-primary;
    background-color: $brand-tertiary;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    padding: 6px 8px;
    font-weight: bold;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 8px 8px 0 0;
  }

  &__ttl {
    font-size: 24px;
    font-weight: bold;
    color: $brand-primary;
    margin-bottom: 20px;

    @include below(1024px) {
      margin-top: 20px;
    }
  }

  &__sbttl {
    font-size: 16px;
    font-weight: bold;
    color: $brand-primary;
    margin-bottom: 20px;
  }

  &__description,
  &__obs {
    iframe {
      width: 100%;
      min-height: 250px;
    }

    ul {
      list-style-type: disc;
      padding-left: 40px;
    }

    ol {
      list-style-type: decimal;
      padding-left: 40px;
    }
  }

  &__icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 100%;

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      margin-right: 5px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &--period {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.515 15.515'%3E%3Cpath data-name='Caminho 2069' d='M0 0h15.515v15.515H0z' fill='none'/%3E%3Cpath data-name='Caminho 2070' d='M12.929 1.939h-.646V.646h-1.29v1.293H4.525V.646H3.232v1.293h-.646a1.3 1.3 0 00-1.293 1.293v10.343a1.3 1.3 0 001.293 1.293h10.343a1.3 1.3 0 001.293-1.293V3.232a1.3 1.3 0 00-1.293-1.293zm0 11.636H2.586v-8.4h10.343z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }

    &--time {
      &:before {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.515 15.515'%3E%3Cpath data-name='Caminho 2071' d='M0 0h15.515v15.515H0z' fill='none'/%3E%3Cpath data-name='Caminho 2072' d='M7.751 1.293a6.465 6.465 0 106.471 6.465 6.461 6.461 0 00-6.471-6.465zm.006 11.636a5.172 5.172 0 115.172-5.172 5.17 5.17 0 01-5.171 5.172z' fill='%23151522'/%3E%3Cpath data-name='Caminho 2073' d='M8.081 4.525h-.97v3.879l3.394 2.036.485-.8-2.909-1.721z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }

    &--pin {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.515 15.515'%3E%3Cpath data-name='Caminho 2074' d='M0 0h15.515v15.515H0z' fill='none'/%3E%3Cpath data-name='Caminho 2075' d='M7.757 1.293a4.522 4.522 0 00-4.525 4.525c0 3.394 4.525 8.4 4.525 8.4s4.525-5.01 4.525-8.4a4.522 4.522 0 00-4.525-4.525zm0 6.141a1.616 1.616 0 111.616-1.616 1.617 1.617 0 01-1.616 1.616z' fill='%23151522'/%3E%3C/svg%3E");
      }
    }
  }

  &__row {
    font-size: 16px;
    color: $neutral-black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--sb {
      justify-content: space-between;
    }

    > div {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__block {
    flex-basis: 100%;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > div {
      margin-top: 25px;

      @include desktop() {
        margin-top: 15px;
      }

      &:nth-child(1) {
        width: 50%;
        @include desktop() {
          width: 45%;
          margin-right: 25px;
        }
      }

      &:nth-child(2) {
        width: 50%;
        text-align: right;
        @include desktop() {
          width: auto;
          margin-right: 15px;
          text-align: left;
        }
      }

      &:nth-child(3) {
        width: 100%;
        @include desktop() {
          width: auto;
        }
      }
    }
  }

  &__btns-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10%;
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;

    @include desktop() {
      padding: 0;
      border-top: 0;
      justify-content: flex-start;
    }

    .o-btn {
      margin: 0 2px 5px;
      @include desktop() {
        margin: 0 5px 5px;
      }
    }
  }
}
