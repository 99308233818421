.o-grid {
  display: flex;

  &--table {
    display: grid;

    &-columns {
      &__1 {
        grid-template-columns: repeat(1, 1fr);
      }
      &__2 {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      &__3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &__4 {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &--buttons {
    &-inline {
      margin: 40px 0;
      display: flex;

      &-start {
        justify-content: flex-start;
      }

      &-end {
        justify-content: flex-end;
      }

      a + a,
      a + button,
      button + button {
        margin-left: 20px;
      }
    }
  }

  &--align-end {
    justify-content: flex-end;
  }

  &--align-center {
    align-items: center;
  }
  &__item {
    width: 100%;
  }
}

.o-row {
  display: flex;
  align-items: center;

  &--inline {
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &--top {
    align-items: baseline;
  }

  &--end {
    &-onmobile {
      @media (max-width: 768px) {
        align-items: flex-end;
      }
    }
  }
}

.o-margin {
  &--bottom {
    &-0 {
      margin-bottom: 0;
    }
    &-10 {
      margin-bottom: 10px;
    }
    &-20 {
      margin-bottom: 20px;
    }
    &-30 {
      margin-bottom: 30px;
    }
    &-40 {
      margin-bottom: 40px;
    }
    &-50 {
      margin-bottom: 50px;
    }
  }
  &--top {
    &-10 {
      margin-top: 10px;
    }
    &-20 {
      margin-top: 20px;
    }
    &-30 {
      margin-top: 30px;
    }
    &-40 {
      margin-top: 40px;
    }
    &-50 {
      margin-top: 50px;
    }
    &-70 {
      margin-top: 70px;
    }
  }
  &--left {
    &-10 {
      margin-left: 10px;
    }
    &-20 {
      margin-left: 20px;
    }
    &-30 {
      margin-left: 30px;
    }
    &-40 {
      margin-left: 40px;
    }
    &-50 {
      margin-left: 50px;
    }
  }
  &--right {
    &-10 {
      margin-right: 10px;
    }
    &-20 {
      margin-right: 20px;
    }
    &-30 {
      margin-right: 30px;
    }
    &-40 {
      margin-right: 40px;
    }
    &-50 {
      margin-right: 50px;
    }
  }
}

.o-padding {
  &--bottom {
    &-0 {
      padding-bottom: 0;
    }
    &-10 {
      padding-bottom: 10px;
    }
    &-20 {
      padding-bottom: 20px;
    }
    &-30 {
      padding-bottom: 30px;
    }
    &-40 {
      padding-bottom: 40px;
    }
    &-50 {
      padding-bottom: 50px;
    }
  }
  &--top {
    &-10 {
      padding-top: 10px;
    }
    &-20 {
      padding-top: 20px;
    }
    &-30 {
      padding-top: 30px;
    }
    &-40 {
      padding-top: 40px;
    }
    &-50 {
      padding-top: 50px;
    }
  }
  &--left {
    &-10 {
      padding-left: 10px;
    }
    &-20 {
      padding-left: 20px;
    }
    &-30 {
      padding-left: 30px;
    }
    &-40 {
      padding-left: 40px;
    }
    &-50 {
      padding-left: 50px;
    }
  }
  &--right {
    &-10 {
      padding-right: 10px;
    }
    &-20 {
      padding-right: 20px;
    }
    &-30 {
      padding-right: 30px;
    }
    &-40 {
      padding-right: 40px;
    }
    &-50 {
      padding-right: 50px;
    }
  }
}
